<template>
  <div :id="id" class="multiselect-custom">
    <label v-if="label.length" class="font-bold" for="">
      {{ label }}
    </label>
    <p v-if="sublabel.length" class="text-[14px]">
      {{ sublabel }}
    </p>
    <VueMultiselect
      v-model="modelValue"
      :options="Array.from(options)"
      :placeholder="placeholder"
      label="label"
      track-by="value"
      :disabled="disabled"
      :loading="isLoading"
      :searchable="searchable"
      :max-height="179"
      :select-label="t('multiselect.selectLabel')"
      :selected-label="t('multiselect.selectedLabel')"
      :deselect-label="t('multiselect.deselectLabel')"
      @search-change="handleSearchChanged"
      @select="handleValueChanged"
      @remove="handleRemovedOption"
    >
      <template #option="optionProps">
        <span v-if="optionProps.option.value !== tmpCustomOptionIdentifier">
          {{ optionProps.option.label }}
        </span>
        <span v-if="optionProps.option.value === tmpCustomOptionIdentifier">
          <FaIcon icon-class="fal fa-plus-circle" /> "{{
            optionProps.option.label
          }}"
          {{ t('multiselect.customAdd') }}
        </span>
      </template>
      <template #caret="{ toggle }">
        <div>
          <div class="multiselect__select" @mousedown.prevent.stop="toggle">
            <FaIcon
              classes="!w-[28px] !h-[28px]"
              icon-class="fal fa-angle-down"
            />
          </div>
        </div>
      </template>
    </VueMultiselect>
  </div>
</template>

<script setup lang="ts">
import VueMultiselect from 'vue-multiselect';
import type { OptionType } from '@/helpers/disturbanceForm';
import FaIcon from '@/components/fa-icon.vue';

const props = defineProps({
  initialOptions: {
    type: Array as PropType<OptionType[]>,
    required: true,
    default: () => [],
  },
  value: {
    type: [Object, null] as PropType<OptionType>,
    required: true,
    default: {} as OptionType,
  },
  id: {
    type: String,
    required: false,
    default: '',
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  placeholder: {
    type: String,
    required: false,
    default: 'Bitte auswählen',
  },
  isLoading: {
    type: Boolean,
    required: true,
    default: false,
  },
  isExtendable: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  searchable: {
    type: Boolean,
    required: false,
    default: true,
  },
  sublabel: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits<{
  (e: 'onValueChanged', value: OptionType): void;
}>();

const { t } = useTrans();
const tmpCustomOptionIdentifier = 'tmpCustomOption';
const customOptionIdentifier = 'customOption';
const options = computed(() => props.initialOptions);
const modelValue = computed({
  get() {
    return props.value;
  },
  set() {
    // Tmp setter
  },
});

function handleSearchChanged(searchQuery: string) {
  if (!props.isExtendable) {
    return;
  }

  const tmpCustomOption = options.value.filter(
    (o) => o.value === tmpCustomOptionIdentifier,
  );

  // If all chars are removed from search, we have to remove custom answer from options
  if (!searchQuery.length) {
    if (tmpCustomOption.length) {
      options.value.filter(removeTmpCustomIdentifier);
    }
    return;
  }

  const optionWithValueAlreadyExists =
    options.value.filter(
      (o) => o.label.toLowerCase() === searchQuery.toLowerCase(),
    ).length > 0;

  if (optionWithValueAlreadyExists) {
    return;
  }

  if (!tmpCustomOption.length) {
    options.value.push({ label: '', value: tmpCustomOptionIdentifier });
  }

  options.value.map((o) => {
    if (o.value === tmpCustomOptionIdentifier) {
      o.label = searchQuery;
    }
  });
}

function handleValueChanged(selectedOption: OptionType) {
  emit('onValueChanged', selectedOption);

  if (!props.isExtendable) {
    return;
  }

  if (selectedOption.value === tmpCustomOptionIdentifier) {
    options.value.filter(removeCustomIdentifier);
    options.value.map((o) => {
      if (o.value === tmpCustomOptionIdentifier) {
        o.value = customOptionIdentifier;
        return o;
      }
    });
  }

  options.value.filter(removeTmpCustomIdentifier);
}

function removeTmpCustomIdentifier(
  object: OptionType,
  index: number,
  arr: OptionType[],
) {
  if (object.value === tmpCustomOptionIdentifier) {
    arr.splice(index, 1);
    return true;
  }

  return false;
}

function removeCustomIdentifier(
  object: OptionType,
  index: number,
  arr: OptionType[],
) {
  if (object.value === customOptionIdentifier) {
    arr.splice(index, 1);
    return true;
  }

  return false;
}

function handleRemovedOption() {
  emit('onValueChanged', null);
}
</script>
<style lang="postcss" scoped>
.multiselect-custom :deep(.multiselect) {
  min-height: 36px;
}

.multiselect-custom :deep(.multiselect__tags) {
  border-radius: 0;
  min-height: 36px;
  height: 36px;
  padding-top: 0;
}

.multiselect-custom :deep(.multiselect__input) {
  padding-top: 1px;
  height: 32px;
}

.multiselect-custom :deep(.multiselect__placeholder) {
  padding-top: 7px;
}

.multiselect-custom :deep(.multiselect__single) {
  padding-top: 7px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.multiselect-custom :deep(.multiselect__option--highlight) {
  color: var(--thm-text-dark);
  background: var(--thm-primary-lighter);
}
.multiselect-custom :deep(.multiselect__option--highlight:after) {
  color: var(--thm-text-base);
  background: var(--thm-primary-lighter);
}

:deep(.multiselect__option--selected) {
  color: var(--thm-white-base);
  background: var(--thm-primary-base);
}
:deep(.multiselect__option--selected:after) {
  color: var(--thm-white-base);
  background: var(--thm-primary-base);
}

:deep(
    li.multiselect__element:hover
      .multiselect__option--highlight.multiselect__option--selected
  ) {
  color: var(--thm-white-base);
  background: var(--thm-status-danger-base);
}
:deep(
    li.multiselect__element:hover
      .multiselect__option--highlight.multiselect__option--selected:after
  ) {
  color: var(--thm-white-base);
  background: var(--thm-status-danger-base);
}

.multiselect-custom :deep(.multiselect__spinner) {
  height: 34px;
}

.multiselect-custom :deep(.multiselect__spinner:before) {
  border-color: #346e73 transparent transparent;
}

.multiselect-custom :deep(.multiselect__spinner:after) {
  border-color: #346e73 transparent transparent;
}

.multiselect-custom :deep(.multiselect__select:before) {
  content: none;
}

.multiselect-custom :deep(.multiselect__select:after) {
  content: none;
}

.multiselect--disabled :deep(.multiselect__select) {
  background: unset;
}

.multiselect-custom :deep(.multiselect__content-wrapper) {
  max-height: 365px !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
